import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../hooks/UseAppContext";
import Checkbox from "../../ui-components/Checkbox";

const ProductFilter = ({close}: any) => {
    const filterRef = useRef<any>(null);
    const { categoryList, filteredSubCategoryList, selectedCategory, handleSelectCategory, selectedSubCategories, handleSelectSubCategories, resetFilter, handleApplyFilter} = useAppContext();
    
    const handleClose = () => {
        close();
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if ((filterRef.current && !filterRef.current.contains(event.target))) {
                close();
            }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const handleCategoryChange = (id: any) => {
        handleSelectCategory(id);
    }

    const handleSubCatrgorySelection = (event: any) => {
        handleSelectSubCategories(event);
    }


    return (
        <div className="z-10 bg-[#252a335c] h-screen fixed w-screen">
           <div className="md:w-[560px] w-full max-w-[560px] bg-white" ref={filterRef}>
                <div className="flex flex-row border-b border-dashed py-3 px-2 border-gray-200 items-center justify-between">
                    <div className="flex flex-row">
                        <div className="me-3">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                            </svg>
                        </div>
                        <h2 className="font-semibold text-md">Filters</h2>
                    </div>
                    <div onClick={handleClose} className="bg-gray-100 w-8 h-8 rounded-full flex justify-center items-center cursor-pointer hover:bg-gray-200">
                        <i className="lni lni-close font-bold text-sm"></i>
                    </div>
                </div>
                <div className="w-full flex flex-row">
                    <div className="w-2/5 bg-slate-50 overflow-auto h-[calc(100vh_-_250px)]">
                        <ul>
                            { categoryList?.map((category: any) => (
                                <li key={category?.id} onClick={ () => handleCategoryChange(category?.id) } className={`px-4 py-3 text-[14px] hover:bg-slate-100 cursor-pointer hover:text-primary ${selectedCategory == category?.id ? 'text-primary bg-slate-100 font-medium':'' }`}>{category?.name}</li>
                            )) }
                        </ul>
                    </div>
                    <div className="w-3/5 py-4 h-full px-3 overflow-auto max-h-[calc(100vh_-_250px)]">
                        <div>
                            { filteredSubCategoryList?.map((cate: any) => (
                                <Checkbox key={cate?.id} checked={selectedSubCategories?.includes(cate?.id)} id={cate?.id} name={cate?.name} onChange={ handleSubCatrgorySelection }/>
                            )) }
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start justify-between h-[120px] p-5 border-t border-dashed border-gray-200">
                    <button type="button" onClick={resetFilter} className="bg-slate-200 px-6 py-2 text-black rounded-lg">Clear All</button>
                    <button type="button" onClick={() => { handleApplyFilter(); handleClose(); }} className="bg-secondary px-6 py-2 text-white rounded-lg">Apply Filter</button>
                </div>
           </div>
        </div>
    )
};

export default ProductFilter;