import React, { useState, useEffect } from 'react';

const Search = ({ onSearch }: any) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
        onSearch(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="relative md:me-3">
        <i className="lni lni-search-alt absolute top-[12px] left-[10px] text-[18px] text-slate-400 font-bold"></i>
        <input type="text" className="border min-w-full md:min-w-[300px] border-[#dbdfe9] px-3 py-2 ps-[32px] rounded-[8px]" placeholder="Search..." 
        value={searchTerm} 
        onChange={handleSearchChange} />
    </div>
  );
};

export default Search;
